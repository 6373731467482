// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chinese-contact-tracing-js": () => import("./../src/pages/chinese-contact-tracing.js" /* webpackChunkName: "component---src-pages-chinese-contact-tracing-js" */),
  "component---src-pages-erin-1-js": () => import("./../src/pages/erin1.js" /* webpackChunkName: "component---src-pages-erin-1-js" */),
  "component---src-pages-erin-2-js": () => import("./../src/pages/erin2.js" /* webpackChunkName: "component---src-pages-erin-2-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-js": () => import("./../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-text-primary-js": () => import("./../src/templates/text-primary.js" /* webpackChunkName: "component---src-templates-text-primary-js" */),
  "component---src-templates-video-primary-js": () => import("./../src/templates/video-primary.js" /* webpackChunkName: "component---src-templates-video-primary-js" */)
}

